@import "styles/variables.colors.module";

.listItem {
  height: 36px;
  line-height: 140%;
  font-size: 13px;
  font-weight: bold;
  color: white;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    path,
    rect {
      fill: white;
    }
  }

  &:hover {
    color: white;
    cursor: pointer;

    svg {
      path,
      rect {
        fill: white;
      }
    }
  }

  &.selected {
    color: black;

    svg {
      path,
      rect {
        fill: black;
      }
    }

    &.disabled {
      background: none;
      cursor: default;
      color: $black-level2;
      svg {
        path,
        rect {
          fill: $black-level2;
        }
      }
    }
  }

  &.disabled {
    color: $black-level2;
    svg {
      path,
      rect {
        fill: $black-level2;
      }
    }

    &:hover {
      cursor: default;
      color: $black-level2;

      svg {
        path,
        rect {
          fill: $black-level2;
        }
      }
    }
  }
  .iconContainer {
    min-width: 33px;
    display: flex;
    align-items: center;
  }

  .selectIconContainer {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .text {
    font-size: 16px;
    flex-grow: 1;
  }
}
