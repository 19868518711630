@import "styles/variables.colors.module";
@import "constants.module";

.root {
  background-color: $blue1;
  width: 257px;
  height: 100vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .logoContainer {
    padding: 24px $sidebarHorizontalGutter 6px;
  }

  .userBarContainer {
    height: 33px;
  }

  .separator {
    margin-top: 10px;
    border-bottom: 1px solid $black-level1;
    margin-bottom: 10px;
  }
  .sidebarHead {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 40px;
  }
  .sidebarContent {
    height: 100%;
    overflow: hidden;
    padding: 0 0 6px;
  }
  .searchInput {
    height: $searchInputHeight;
    width: 100%;
    box-sizing: border-box;
    padding: 0 $sidebarHorizontalGutter;
    margin: 0;
  }
  a {
    text-decoration: none;
    color: white;
  }
}
