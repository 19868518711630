// lok  variables

$blue1: #2699fb;
$blue2: #f1f9ff;
$blue3: #bce0fd;

// Black
$black90: #312f30;
$black80: #5a5859;
$black60: #838282;
$black40: #adacad;
$black20: #d6d6d6;
$black10: #eaeaea;
$black5: #faf7f8;

//white
$white: #ffffff;

//// Primary
$primary-main: #2f7cf7;
$primary-dark: #1d68df;
$primary-light: #639cf9;
$primary-extra-light: #e2f2ff;

$secondary-main: #2f7cf7;

$red-light: #ffeeee;
$green-light: #f4fff5;

// Gray
$gray2: #4f4f4f;

$grayBtnText: #dddccc;
$grayButton: #c4c4c4;

$gray: #f1f3f6;
$lightGray: #e0e0e0;

$backgroundColor: #e5e5e5;

$textColorBlack: #000000;
$textColorGray: #9f9f9f;
$textButtonWhite: #ffffff;

$actionText: $primary-main;
$green: #6fcf97;

$errorColor: #e72d2d;
$red1: #FF3131;

$pagePadding: 30px;
$buttonPadding: 10px;

$darkGray: #2d3446;
$darkColor: rgba(0, 0, 0, 0.3);
$darkGrayText: #c3c3c3;

$tableHeadColor: #f1f3f6;

$borderInput: $black20;

$titleColor: #000000;

$alertErrorColor: #f27474;
$alertSuccessColor: #48a651;
$warningColor: #fea15a;

$alertTitleColor: rgba(0, 0, 0, 0.65);
$alertTextColor: rgba(0, 0, 0, 0.64);

$linearGradient: linear-gradient(
  0deg,
  #312f30 92.86%,
  rgba(49, 47, 48, 0) 106.25%
);

$status-on: #48a651;

$strokeColor: #ebcec3;

//Export variables to usage in js files;
:export {
  black90: #312f30;
  black80: #5a5859;
  black60: #838282;
  black40: #adacad;
  black20: #d6d6d6;
  black10: #eaeaea;
  black5: #faf7f8;

  whiteColor: $white;

  primaryMain: #2f7cf7;
  primaryDark: #1d68df;
  primaryLight: #639cf9;
  primaryExtraLight: #e2f2ff;

  secondaryMain: #2f7cf7;
}

// Black
$black-level0: #1a0404;
$black-level1: #332627;
$black-level2: #4d3d3e;
$black-level3: #665555;
$black-level4: #806f6f;
$black-level5: #998a8a;
$black-level6: #b2a6a6;
$black-level7: #ccc2c2;
$black-level8: #f5ebeb;
$black-level9: #fffcfd;

//white
$white: #ffffff;

// Primary
$primary-main: #f72a30;
$primary-main-hover: #ff484e;

$red-light: #ffeeee;
$green-light: #e9f7ef;

// Gray
$gray2: #4f4f4f;
$gray3: #b3a6a6;
$gray4: #ccc2c2;

$grayBtnText: #dddccc;
$grayButton: #c4c4c4;

$gray: #f1f3f6;
$lightGray: #e0e0e0;

$backgroundColor: #e5e5e5;

$textColorGray: #9f9f9f;
$textButtonWhite: #ffffff;

$actionText: $primary-main;
$green: #6fcf97;

$errorColor: #fea15a;

$pagePadding: 30px;
$buttonPadding: 10px;

$darkGray: #2d3446;
$darkColor: rgba(0, 0, 0, 0.3);
$darkGrayText: #c3c3c3;

$tableHeadColor: #f1f3f6;

$borderInput: $black-level7;

$alertErrorColor: #f27474;
$alertSuccessColor: #48a651;
$warningColor: #fea15a;
$warningLight: #fff8f2;

$alertTitleColor: rgba(0, 0, 0, 0.65);
$alertTextColor: rgba(0, 0, 0, 0.64);

$linearGradient: linear-gradient(
  0deg,
  #312f30 92.86%,
  rgba(49, 47, 48, 0) 106.25%
);

$status-on: #48a651;

//Export variables to usage in js files;
:export {
  blue1: $blue1;

  black90: $black-level2;
  black80: $black-level4;
  black60: $black-level5;
  black40: $black-level6;
  black20: $black-level7;
  black10: $black-level8;
  black5: $black-level9;
  black8: $black-level8;
  alertSuccessColor: $alertSuccessColor;
  whiteColor: $white;

  primaryMain: $primary-main;
  primaryDark: $black-level2;
  primaryExtraLight: $black-level9;

  secondaryMain: $black-level2;
  strokeColor: $strokeColor;
}
