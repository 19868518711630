@import "styles/common.module.scss";

.logoHeader {
  width: 100%;
  height: $logoHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  .gaucherLogo {
    svg {
      height: 130px;
      padding-top: 10px;
    }
  }
  .hubLogo {
    svg {
      height: 100px;
      padding-top: 10px;
    }
  }
  .gaucherContainer {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
