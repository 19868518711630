@import "styles/common.module.scss";
@import "styles/variables.colors.module.scss";

body {
  margin: 0px;
  position: relative;
  color: $black90;
}

@media all and (min-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media all and (max-width: 959px) and (min-width: 600px) {
  html {
    font-size: 12px;
  }
}

@media all and (max-width: 599px) and (min-width: 320px) {
  html {
    font-size: 10px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
  box-shadow: none;
  height: 50px;
}

::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

::-webkit-scrollbar-track {
  background-color: #c8c8c8;
  border-radius: 0;
}

::-webkit-scrollbar-corner {
  background-color: #c8c8c8;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  .content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
}

.wrapper {
  @extend .loggedOutWrapperLarge;

  .loginContainer {
    @extend .container;
    margin-top: 90px;
  }
  .submitSection {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  .loginBtn {
    @extend .submitButton;
    width: 100%;
    margin-bottom: 10px;
  }
  .forgetPassContainer {
    width: 100%;
    text-align: right;
  }
  .forgetPass {
    text-decoration: none;
    color: $blue1;
    font-size: 16px;
  }
}

.loginDetails {
  display: flex;
  flex: 1;
  @extend .flexRow;
  .form {
    width: 360px;
    @media all and (max-width: 599px) and (min-width: 320px) {
      width: 300px;
    }
  }
}
