@import "./inputs.module.scss";
@import "texts.module";
@import "./tables.module.scss";
@import "./buttons.module.scss";
@import "./navigations.module.scss";

$logoHeight: 130px;
$titleSectionHeight: 112px;

.wrapperLarge {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.container {
  @media all and (max-width: 599px) and (min-width: 320px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.cardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3px 40px 0 40px;
}

.listContainer {
  @extend .cardContainer;
  height: calc(100% - #{$logoHeight});
  padding: 0;
  overflow: hidden;
}
.titleSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  height: $titleSectionHeight;
  box-sizing: border-box;

  .title {
    margin: 0;
    padding: 0;
    color: $blue1;
  }
}

.tableContainer {
  display: flex;
  height: auto;
}

.title {
  color: $blue1;
}

.subTitle {
  font-size: 18px;
  color: $blue1;
}

.fontFamilySerif {
  font-family: sans-serif;
}

.fontFamilyRoboto {
  font-family: "Roboto";
}

.fontRegular {
  font-weight: normal;
}

.fontBold {
  font-weight: bold;
}

.textTitle {
  color: $actionText;
}

.button {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  @extend .flexCenter;
  border: none;
  min-width: 85px;
  cursor: pointer;
  @media all and (max-width: 599px) and (min-width: 300px) {
    font-size: 18px;
  }
}

.submitButton {
  @extend .btn_medium_default;
}

.flex {
  display: flex;
}
.flexCenterColumn {
  display: flex;
  justify-content: center;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  flex-flow: column;
}

.flexRow {
  flex-flow: row;
}

.cursorPointer {
  cursor: pointer;
}

.actionText {
  color: $blue1;
  cursor: pointer;
}

.defaultTitleText {
  color: $textColorGray;
}

.error {
  color: $errorColor;
  padding-top: 6px;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  margin: 0 0 42px 0;
}

.tableBorder {
  border: 1px solid $lightGray;
}

.formControl {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.link {
  @extend .btn_link_medium;
}
.dot {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.statusOn {
  background: $status-on;
  @extend .dot;
}

.statusOf {
  background: orange;
  @extend .dot;
}

.statusFailed {
  background: red;
  @extend .dot;
}
.divider {
  border: 0.5px solid $grayButton;
  margin-top: 0;
}
.ellipsesText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loggedOutWrapperLarge {
  @extend .wrapperLarge;
  display: flex;
  align-items: center;
  @extend .flexColumn;
}
